import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import CookiesConsent from "../components/CookiesConsent"

const NotFoundPage = () => (
  <Layout meta={{}} title="Page not found">
    <h1
      style={{
        color: 'var(--primary-navy)',
        width: '100%',
        display: 'block',
        padding: '0 12px',
        position: 'relative',
        maxWidth: 1142,
        marginTop: 50,
        fontFamily: '"Butler", serif',
        fontWeight: 500,
        lineHeight: 1.44,
        marginBottom: 32,
        fontSize: 36,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >Oops! Sorry, we can't find that page</h1>
    <p
      style={{
        color: 'var(--primary-navy)',
        width: '100%',
        display: 'block',
        padding: '0 12px',
        position: 'relative',
        fontSize: 16,
        maxWidth: 1142,
        marginTop: 0,
        fontFamily: '"Oxygen", sans-serif',
        lineHeight: 1.7,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 32,
        letterSpacing: '0.016em',
      }}
    >
      The link you followed may be broken, or the page may have been removed.
    </p>
    <p
      style={{
        color: 'var(--primary-navy)',
        width: '100%',
        display: 'block',
        padding: '0 12px',
        position: 'relative',
        fontSize: 16,
        maxWidth: 1142,
        marginTop: 0,
        fontFamily: '"Oxygen", sans-serif',
        lineHeight: 1.7,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 32,
        letterSpacing: '0.016em',
      }}
    >
      Please go back to the previous page or return to the{" "}
      <Link to="/" style={{
        color: '#009f4d',
        textDecoration: 'underline !important',
        fontWeight: 'bold',
      }}>homepage.</Link>
    </p>
    <CookiesConsent></CookiesConsent>
  </Layout>
)

export default NotFoundPage
